import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { Link as Scroll } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const navs = [
  {
    title: "费用",
    link: "price",
  },
  {
    title: "关于合同",
    link: "contract",
  },
  {
    title: "关于功能",
    link: "function",
  },
  {
    title: "关于支持",
    link: "support",
  },
  {
    title: "关于该活动",
    link: "campaign",
  },
  {
    title: "其他",
    link: "others",
  },
]

const Qa = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="FAQ |  常见问题"
          pagedesc="回答有关费用、合同、功能、支持、活动和其他有关组织Twitter活动的问题，供PARKLoT使用。"
          pagepath={location.pathname}
        />
        <main className="qa-list">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container m-auto">
              <h1 className="text-white font-bold">常见问题</h1>
            </div>
          </section>
          <section className="navigation">
            <div className="container px-4 py-4 md:py-20 md:px-0">
              <ul className="qa-list__nav md:flex md:flex-wrap w-full">
                {navs.map((nav, index) => {
                  return (
                    <li
                      key={index}
                      className="border border-lightgrey rounded bg-white hover:bg-palegrey transition-all md:mb-6 mb-3"
                    >
                      <Scroll
                        to={nav.link}
                        className="p-3 md:pb-2 block"
                        smooth={true}
                      >
                        <div className="text-center flex justify-between align-center md:block">
                          <span className="text-sm text-text font-bold md:block">
                            {nav.title}
                          </span>
                          <FontAwesomeIcon
                            className="text-lightgrey"
                            icon={faAngleDown}
                            size="lg"
                          />
                        </div>
                      </Scroll>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 料金 */}
          <section id="price">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                费用
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在这个价格下，你到底能开展多少次活动？ 是否有任何额外的费用？
                </dt>
                <dd className="qa-list__data">
                  如果你注册了一个固定价格的计划，你可以在没有额外费用的情况下开展任意数量的活动。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否根据参赛者、获奖者和参与的数量收取现收现付的费用，或者对数据的获取有限制？?
                </dt>
                <dd className="qa-list__data">
                  目前，没有现收现付的收费。
                  <br />
                  但是，如果该账户现有粉丝超过10万，并且预期参与人数在一天内超过1万，请提前与我们协商。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  下载CSV格式的活动结果需要多少钱？
                </dt>
                <dd className="qa-list__data">
                  你可以在基本费用内下载文件。 不需要另外收费。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  如果活动是 "每天 "或 "每周 "进行，价格是否有变化？
                </dt>
                <dd className="qa-list__data">
                  不，没有变化。
                  <br />
                  无论是每天（31次/月）还是每周（4次/月）开展活动，价格都一样。
                  *运动不能在同一时间举行。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  有哪些支付方式和支付网站？
                </dt>
                <dd className="qa-list__data">
                  系统费必须在开始使用前支付。
                  <br />
                  我们将开具发票，付款必须在活动开始前至少一天进行。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否有折扣，例如年度合同的折扣？
                </dt>
                <dd className="qa-list__data">
                  是的，当你签署年度合同时，我们提供费用折扣。
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 契約 */}
          <section id="contract">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                关于合同
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我想在PARKLoT开展活动，我可以在哪里注册？
                </dt>
                <dd className="qa-list__data">
                  <a
                    className="qa-list__text-link"
                    href="/forms/apply"
                    target="_blank"
                    rel="noreferrer"
                  >
                    请使用此申请表格进行申请。
                  </a>
                  关于从申请到活动开始的流程，请参考
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/starting-a-campaign" className="qa-list__link">
                    "从申请到活动开始的流程"。
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否可以在申请前检查管理屏幕的实际操作和演示屏幕上的参与者的线索等？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。 欲了解更多信息，请联系。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我正在考虑引进PARKLoT系统，能否给我一本小册子或介绍性材料？
                </dt>
                <dd className="qa-list__data">
                  这些文件可从文件下载页面立即获得PDF格式。
                  <br />
                  如果你想收到纸质副本，
                  <a
                    className="qa-list__text-link"
                    href="https://hub.park-lot.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    请使用联系表与我们联系。
                  </a>
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="https://hub.park-lot.com/document-download"
                    className="qa-list__link"
                  >
                    下载 "三分钟内的PARKLoT"
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  签署合同后，运动组织者应该做什么？
                </dt>
                <dd className="qa-list__data">
                  请确认你的TwitterAPI应用并登录PARKLoT系统。
                  <br />
                  登录后，可以在管理页面上创建活动。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我正在考虑在多个Twitter账户上开展活动。 合同是按账户计算的吗？
                  还是每个公司？
                </dt>
                <dd className="qa-list__data">
                  该合同是以每个Twitter账户为基础的。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在我的Twitter账户上为任何产品开展活动吗？
                </dt>
                <dd className="qa-list__data">
                  是的，基本上任何账户都可以组织一个活动。
                  <br />
                  然而，对于被归类为与赌博有关的产品、成人用品和虚拟货币，需要额外的工时，并将另行收费。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我正在用另一家公司的工具持有一个即时赢家，正在考虑转换。
                  我可以只要求一个报价吗？
                </dt>
                <dd className="qa-list__data">
                  是的，请直接询问报价。
                  <br />
                  PARKLoT允许你以较低的成本和固定的价格持有任意数量的即时赢利。
                  <br />
                  此外，我们有资源来发展我们自己的运动，所以我们也可以提出建议来提高其他公司目前正在进行的运动的水平。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我是否需要遵循任何取消的程序？
                </dt>
                <dd className="qa-list__data">不是特别需要。</dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 機能 */}
          <section id="function">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                关于功能
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">订阅计划中包括哪些功能？</dt>
                <dd className="qa-list__data">
                  关注和转发形式的即时获胜活动功能是每月提供的基本功能。
                  可以添加的其他免费可选的功能包括。
                  <br />
                  ・收集送货地址或电子邮件地址
                  <br />
                  ・自动向运动参与者传送信息
                  <br />
                  ・引导人们进入网站
                  <br />
                  ・导致线
                  <br />
                  ・自动传输固定的礼品代码（为每个获奖者自动分配不同的代码，如亚马逊礼品代码，是一个付费选项）
                  <br />
                  ・设置每个礼物的棋子数量和获胜概率
                  <br />
                  ・根据我们的标准，对垃圾邮件进行评估
                  <br />
                  ・设置中奖者被选中时显示的图像和措辞
                  <br />
                  ・设置投标不成功时要显示的图像和文字
                  <br />
                  ・收集运动参与者的信息并输出到CSV
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  可以收集的转发用户数据的最大数量是多少？
                </dt>
                <dd className="qa-list__data">
                  每天可以收集超过100,000个案例。
                  如果你需要收集更多，请联系你的联系人。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我在多大程度上可以获得参与活动的推特账户的信息？
                </dt>
                <dd className="qa-list__data">
                  基本上可以收集到Twitter上公开的资料数据。
                  个人账户上被设置为隐私的信息不能被收集。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否有可能赢得所有运动参与者而不致错过？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否有任何机制可以联系到参与活动但未被抽中的用户？
                </dt>
                <dd className="qa-list__data">
                  是的，有的。
                  宣传活动可以设计成引导人们到LINE、Instagram或他们自己的网站。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以设置多少个不同的奖项？
                </dt>
                <dd className="qa-list__data">最多可以设置三种类型。</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我开始了一个有两个不同奖项设置的活动。
                  我可以在路上再加一个吗？
                </dt>
                <dd className="qa-list__data">
                  一旦帖子被自动推送，活动开始，就不能再增加其他奖品。
                  <br />
                  对于已经设置的两个奖项，"中奖概率 "和 "奖品数量
                  "可以在活动开始后进行更改。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  由PARKLoT组织的活动的结果通知是以回复还是URL的形式出现？
                </dt>
                <dd className="qa-list__data">
                  URL类型。 在直接信息中通过URL通知结果。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/reply-based-or-url-based"
                    className="qa-list__link"
                  >
                    为什么PARKLoT使用URL类型
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在URL转换后的结果通知页上使用视频进行推广吗？
                </dt>
                <dd className="qa-list__data">
                  所有图片、GIF和视频都可以显示在结果通知页上。
                  <br />
                  基本计划只允许显示jpeg/gif文件。
                  <br />
                  视频也可以显示，但需要额外费用。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  開催中に当選確率を変更することは可能ですか？
                </dt>
                <dd className="qa-list__data">
                  有没有可能在活动中改变获胜的概率？
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/can-i-change-the-odds" className="qa-list__link">
                    如何改变获胜的概率。
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我计划开展为期三天的活动。 能否为每一天设定获胜的概率？
                </dt>
                <dd className="qa-list__data">
                  对于一个连续三天的活动，不可能分别设置日子。
                  <br />
                  在活动期间，获胜的概率可以改变，我们要求你在监测情况的同时操作活动。
                  <br />
                  如果连续举行三次为期一天的运动，可以为每一天设定获胜的概率。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否可以在活动期间改变获奖者的数量？
                </dt>
                <dd className="qa-list__data">
                  是的，在活动期间可以在管理屏幕上改变中奖者的数量。
                  <br />
                  '达到1万次转发，你的获奖人数就会翻倍！'
                  <br />
                  活动的设计可以让参与者产生一种团结的感觉，比如说
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">我可以筛选出获奖者吗？</dt>
                <dd className="qa-list__data">
                  是的，你可以根据以下标准来过滤获奖者。
                  <br />
                  ・是否允许默认配置文件用户获胜
                  <br />
                  ・关注者的最低数量
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我有一个推特转发活动，没有使用PARKLoT就进行了。
                  我以后能不能检索到参加这个活动的用户？
                </dt>
                <dd className="qa-list__data">
                  联系。
                  <br />
                  由于Twitter的规范，转发用户通常只能检索到最后100个转发，
                  <br />
                  但在某些情况下，你可能会检索到更高的数字。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/past-retweet-campaigns"
                    className="qa-list__link"
                  >
                    PARKLoT的转发用户收集机制
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在报告中看到哪些信息？
                </dt>
                <dd className="qa-list__data">
                  [管理屏幕上提供的信息]
                  <br />
                  ・追随者的数量增加
                  <br />
                  ・参与运动的人数
                  <br />
                  ・冠军（实时）
                  <br />
                  ・获奖者的地址（只有在收集设置开启的情况下）。
                  <br />
                  ・获奖者的电子邮件地址（仅当收集设置开启时）。
                  <br />
                  [可在CSV中给出参与者的信息]
                  <br />
                  ・追随者的数量
                  <br />
                  ・追随者的数量
                  <br />
                  ・推文的数量
                  <br />
                  ・概况
                  <br />
                  ・转发时间
                  <br />
                  ・是否关注该公司的官方推特账户
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  您推荐哪种方法，是使用PARKLoT系统收集地址，还是在礼品收据的措辞中放一个URL，然后用谷歌表格等收集？
                </dt>
                <dd className="qa-list__data">
                  PARKLoT地址收集表要求你用你的Twitter账户登录（OAuth登录）。
                  <br />
                  这是一个防止除获奖者以外的其他人提交表格的机制。
                  <br />
                  使用PARKLoT的地址收集表有助于防止欺诈，如身份盗窃或与其他没有赢得活动的用户分享表格的URL，以进入该活动。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoT奖项门户上的活动详情页是否自动创建？
                </dt>
                <dd className="qa-list__data">
                  是的，详情页是自动创建的。
                  <br />
                  该网站的上市预计将传播到约15,000（截至2022年9月底）的网站注册用户。
                  <br />
                  此外，这也增加了他们继续参与活动的可能性。
                  <br />
                  也可以用自己的域名进行操作。 (需要额外收费)
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="https://goodluck.park-lot.com/"
                    className="qa-list__link"
                  >
                    奖品门户网站PARKLoT
                  </a>
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* サポート */}
          <section id="support">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                关于支持
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以要求PARKLoT代表我购买亚马逊的礼品券来分发吗？
                </dt>
                <dd className="qa-list__data">
                  是的，我们可以；我们对代表我们购买的亚马逊礼品券收取10%的佣金。
                  <br />
                  礼券价格和佣金必须在活动开展前存入。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我能否请求在规划和管理社交网络活动方面的支持？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。 欲了解更多信息，请联系。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以要求你们投放和管理我的广告吗？
                </dt>
                <dd className="qa-list__data">
                  是的，我们可以帮助你。
                  <br />
                  请与我们联系，了解广告业务的代理费情况。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  你能代表获奖用户送出礼物吗？
                </dt>
                <dd className="qa-list__data">
                  不向中奖用户提供递送服务。 获奖者的交付信息可以用CSV格式导出。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  你能协助我获得一个认证徽章吗？
                </dt>
                <dd className="qa-list__data">
                  是的，你可以。 欲了解更多信息，请联系。
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* キャンペーン */}
          <section id="campaign">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                关于该活动
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">什么是即时赢？</dt>
                <dd className="qa-list__data">
                  即时中奖是抽奖活动的一个通用术语，抽奖结果在你进入时就已经知道了。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Twitter Instant Win是如何运作的？
                </dt>
                <dd className="qa-list__data">
                  在活动期间，该方案收集转发指定推文的用户的数据。
                  <br />
                  检索到转发的用户数据后，由程序自动抽签。
                  抽奖结果是通过Twitter的API直接发送的。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/how-twitter-instant-win-works"
                    className="qa-list__link"
                  >
                    Twitter Instant Win是如何运作的
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我是即时赢取活动的新手，你能在多大程度上帮助我？
                </dt>
                <dd className="qa-list__data">
                  考虑到销售促进的主要目标，我们将根据我们一贯为你制定的时间表，
                  <br />
                  从活动内容到所需的图像材料和操作，为你提供支持。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  使用即时赢是否必须收取广告费？
                </dt>
                <dd className="qa-list__data">
                  不，不需要。
                  <br />
                  如果在回复中通知结果或预计有大量参与者，则可能需要收取广告费。
                  请与我们联系，以获得更多信息。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">可以组织哪些运动？</dt>
                <dd className="qa-list__data">
                  可以组织 "关注和转发活动"、"标签活动 "和 "提交收据活动"。
                  <br />
                  可以根据目标受众和所处理的产品和服务来选择绘画方法和礼物。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/types-of-campaigns" className="qa-list__link">
                    可以在PARKLoT中组织的运动类型
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  你对运动的持续时间或频率有什么建议吗？
                </dt>
                <dd className="qa-list__data">
                  许多账户每月持有两到四次，每次三到七天。
                  <br />
                  提高活动效果的诀窍是连续举办至少三个月，同时审查奖品的内容等。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/blog/fan-marketing" className="qa-list__link">
                    如何运行一个专业的Twitter即时赢取活动
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  每个人可以参加一次活动吗？
                </dt>
                <dd className="qa-list__data">
                  是的，标准设置允许每天最多参与一次。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在没有任何麻烦的情况下进行即时获胜的活动吗？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。
                  <br />
                  PARKLoT有一个 "所有申请人在场 "的功能。
                  <br />
                  可以向所有错过抽奖的参与者发送优惠券代码或QR码。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以设置一个条件来关注一个以上的账户吗？
                </dt>
                <dd className="qa-list__data">
                  是的，有可能设置后来的抽奖，而不是即时赢。
                  <br />
                  然而，PARKLoT并不推荐这样做，
                  <br />
                  因为这可能会增加参与用户的努力，并减缓扩散的速度。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  运动最早什么时候可以开始？
                </dt>
                <dd className="qa-list__data">
                  如果你有一个Twitter账户、活动名称、礼物、活动期间和提交推文的图片，你可以在短短10分钟内开始你的活动。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以开展什么样的Twitter活动？
                </dt>
                <dd className="qa-list__data">
                  ・关注和转发
                  <br />
                  ・关注和喜欢
                  <br />
                  ・关注并引用转发
                  <br />
                  ・关注和标签，例如。
                  <br />
                  其他应用方法也很灵活。 请与我们联系以了解更多信息。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我看到了这样的描述："在Twitter的活动方式中，我们只提供Like活动"。你不能在PARKLoT中这样做吗？
                </dt>
                <dd className="qa-list__data">
                  你也可以在PARKLoT上开展Like活动。
                  <br />
                  还有一些已经实施的活动的例子，如
                  "如果你~~就喜欢我们，如果你00就转推我们"。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我需要获得一个Twitter开发者账户和一个API密钥吗？
                </dt>
                <dd className="qa-list__data">
                  如果你想运行一个即时抽奖的活动，你必须获得Twitter的API。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  用一个新的Twitter账户申请API很困难吗？
                </dt>
                <dd className="qa-list__data">
                  新账户并不特别困难。
                  <br />
                  我们还准备了一份文件，你在与Twitter公司沟通时可以参考。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">开始运动的过程是什么？</dt>
                <dd className="qa-list__data">
                  基本流程是：申请--数据准备和支付--活动创建--活动开始。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/starting-a-campaign" className="qa-list__link">
                    活动开始前的步骤
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  活动推文自动发布后，组织者在推特上有什么应该做的吗？
                </dt>
                <dd className="qa-list__data">
                  不需要特别的工作，因为抽奖是自动的，
                  <br />
                  但为了进一步促进活动，建议将相关的推文 "钉在你的档案上"。
                  <br />
                  PARKLoT的官方账户也将通过引用和转发来帮助传播。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以分发可在店内使用的优惠券吗？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  能否测量分发的优惠券的数量？
                </dt>
                <dd className="qa-list__data">
                  是的，使用的张数是可以测量的。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以开展双重机会运动吗？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以改变关注和转发后发送的DM的措辞吗？
                </dt>
                <dd className="qa-list__data">
                  措辞不能改变。 可提供定制服务，但需支付额外费用。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否有可能向未被选中的参与者发送DM？
                </dt>
                <dd className="qa-list__data">
                  是的，即使在有标签的情况下，也可以发送DM。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我每天可以发送多少条DM，把赢家和输家加起来？
                </dt>
                <dd className="qa-list__data">
                  根据Twitter的规则，限制是1000条信息/天。
                  (经核实的账户有15,000条信息)。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以分发亚马逊礼券作为活动奖品吗？
                </dt>
                <dd className="qa-list__data">
                  是的，"亚马逊礼券 "可以作为活动的奖品发放。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/can-i-distribute-amazon-gift"
                    className="qa-list__link"
                  >
                    分发亚马逊礼券
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在向获奖者发送DM时，自动数字礼券分发活动出现了错误。
                  未发送的礼品代码会如何处理？
                </dt>
                <dd className="qa-list__data">
                  它不能结转到下一次运动。 传输错误的清单可以共享，应单独处理。
                </dd>
              </dl>

              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在便利店和甜品店可以兑换的冰激凌和糖果是否可以设置为奖品？
                </dt>
                <dd className="qa-list__data">
                  是的，你可以。
                  <br />
                  有与各种便利店和冰淇淋店合作购买数字礼品的服务，如7-Eleven、Lawson
                  Famima和其他。
                  <br />
                  请从该服务处购买。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以把完成调查作为参与活动的条件吗？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。
                  <br />
                  欲了解更多信息，请联系。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在PARKLoT中创建活动的推荐环境是什么？
                </dt>
                <dd className="qa-list__data">
                  是的，我们建议使用Chrome作为浏览器。
                  <br />
                  (即使你在Windows机器上使用Chrome，在极少数情况下也可能导致问题）。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以收集有标签的帖子并在我的网站上发布吗？
                </dt>
                <dd className="qa-list__data">
                  是的，Twitter和Instagram上带有标签的帖子可以被自动收集并显示在公司网站上。
                  <br />
                  只需嵌入我们发布的标签就可以开始了。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我是否也可以以我的名义要求在我的网站上嵌入有标签的帖子？
                </dt>
                <dd className="qa-list__data">
                  嵌入本身不能由我们处理，因为我们需要授权才能访问网站。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我们计划开展为期七天的活动；开展连续七天的活动（七篇文章）与开展连续七天的活动（一篇文章）有什么区别？
                </dt>
                <dd className="qa-list__data">
                  7 建议捐款的类型。
                  <br />
                  如果不做广告，第二天之后印象就会下降，参与率也会降低。
                  <br />
                  每天发帖，更容易鼓励和联系用户参与。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在我的网站上使用有标签的帖子，是否需要贡献者的许可？
                </dt>
                <dd className="qa-list__data">
                  不，不需要。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在你的网站上发布有标签的帖子时，你能选择哪些帖子来显示吗？
                </dt>
                <dd className="qa-list__data">
                  是的，你可以显示或隐藏帖子。
                  <br />
                  基本设置为隐藏，所以任何不适当的图像都不会显示。 请大家放心。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在你的网站上发布有标签的帖子时，有多少帖子被显示出来？
                </dt>
                <dd className="qa-list__data">
                  默认情况下，将显示50个帖子。
                  可提供定制服务，但需支付额外费用。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在自己的网站上改变带标签帖子的设计吗？
                </dt>
                <dd className="qa-list__data">
                  是的，这可以做到。 收取50,000日元起的可选费用。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当在你自己的网站上发布有标签的帖子时，你多长时间检索一次帖子？
                </dt>
                <dd className="qa-list__data">
                  它每15分钟获得一次。
                  <br />
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* その他 */}
          <section id="others">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                其他
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以要求你为我的活动制作横幅图片吗？
                </dt>
                <dd className="qa-list__data">
                  是的，我们可以提供这项服务。 请告知您的联系人以了解更多信息。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在内部创建活动横幅图像时，应采取哪些预防措施？
                </dt>
                <dd className="qa-list__data">
                  某些类型的礼券需要事先筛选。 请与我们联系以了解更多信息。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  对横幅图像是否有一个尺寸规格？
                </dt>
                <dd className="qa-list__data">
                  推荐的横幅图片尺寸为1200 x 675或1080 x 1080。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在 "用Twitter登录 "和 "链接到应用程序 "时，安全性是否安全？
                  我是否会被收费？
                </dt>
                <dd className="qa-list__data">
                  除了Twitter设定的公开信息外，任何个人或其他信息都不会被泄露。
                  <br />
                  你将不会被收取服务费。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在通知我选择结果的DM中发送附件吗？
                </dt>
                <dd className="qa-list__data">
                  如果你想使用PARKLoT功能发送图片或视频等附件，你需要定制它。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/can-i-send-attachments-via-dm"
                    className="qa-list__link"
                  >
                    通知选举结果的DM的附件
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否可以自定义显示中奖结果的屏幕的表现形式？
                </dt>
                <dd className="qa-list__data">
                  是的，它可以得到支持。
                  <br />
                  可以用符合品牌形象的图像和字符来展示制作，并以产品或服务的名称给参与者留下深刻印象。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/create-scratch-campaign"
                    className="qa-list__link"
                  >
                    获奖者结果显示屏幕的定制
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在实施 "即时赢
                  "时，当收到大量的申请时，是否会出现延误或其他问题？
                </dt>
                <dd className="qa-list__data">
                  由于该系统的原因，在极少数情况下可能会出现延误。
                  <br />
                  为了处理这种情况，我们采用了一个流程，允许用户从活动帖子中的URL检查结果。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我想把Follow＆RT活动的推文也作为促销广告（促销推文）使用。
                  你能把它们连在一起吗？
                </dt>
                <dd className="qa-list__data">
                  是的，它们可以被联系起来。
                  <br />
                  首先，在你的Twitter广告账户上发布一条促销推文。
                  <br />
                  然后你可以与我们分享相关推特的URL，我们将把它链接到PARKLoT即时赢取系统。
                  <br />
                  当参与者关注并RT相关推文时，抽奖活动就会自动启动并发送DM。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当链接到促销推文时，分享推文网址后需要多长时间才能完成链接？
                </dt>
                <dd className="qa-list__data">完成联动大约需要30分钟。</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当链接到促销推文时，在链接完成前通过关注和RT申请的参与者会怎样？
                </dt>
                <dd className="qa-list__data">
                  由于数据是在时间上追溯检索的，所以在URL共享和完成链接之间的参与者不会被泄露。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Twitter的旋转木马广告是否也能与PARKLoT一起用于即时赢取活动？
                </dt>
                <dd className="qa-list__data">
                  是的，它可以被链接。 请在提交后告知我们该网址。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我们已经开展了一段时间的关注和转发活动。
                  参与者的数量已经停滞不前，你有什么计划让它变得更新鲜吗？
                </dt>
                <dd className="qa-list__data">
                  可以组织刮奖活动、老虎机活动和诊断性内容。
                  <br />
                  增加舞台效果可以激发参与的积极性。
                  <br />
                  欲了解更多信息，请联系。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  在拉霸活动的情况下，我可以从结果通知网址中拉霸多少次，每次都是抽奖吗？
                </dt>
                <dd className="qa-list__data">
                  不，只进行一次抽签。
                  <br />
                  无论老虎机屏幕显示多少次，抽奖的结果都是一样的。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以只使用它来收集转发用户吗？
                </dt>
                <dd className="qa-list__data">
                  是的，我们也可以列出转发了相关推文的用户。
                  <br />
                  也支持列出喜欢和回复的内容。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以向回复过以前推文的用户发送一批DM吗？
                </dt>
                <dd className="qa-list__data">
                  有关向回复、喜欢和转发用户发送批量DM的信息，请联系我们。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我是否也可以组织在线财富和刮奖游戏？
                </dt>
                <dd className="qa-list__data">
                  该格式对应的是，从LINE转到链接，登录LINE并抽签。 (链接到LINE)
                  <br />
                  可以创建特殊网站，分发礼品代码，收集地址等，请与我们联系。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以只要求收集转发或图片吗？
                </dt>
                <dd className="qa-list__data">是的，你可以要求。</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以推广不止一个礼物活动吗（即时赢）？
                  其目的是介绍和提高对你的产品的认识。
                </dt>
                <dd className="qa-list__data">
                  是的，我们可以制作易于在Twitter上浏览和分享的卡通广告。
                  <br />
                  这是一种极具吸引力的销售推广技巧，以故事形式介绍产品。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我想鼓励大家注册一个企业LINE账户，有什么好的计划吗？
                </dt>
                <dd className="qa-list__data">
                  是的，建议使用自动内容DM分发功能。
                  <br />
                  可以向转发的用户自动发送DM，鼓励他们链接到LINE账户或公司网站，下载图片等。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我需要在多少天内寄出我的奖品？
                </dt>
                <dd className="qa-list__data">
                  没有具体的规则，但在大多数情况下，运输需要一个星期左右。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以在我的网站上安装竞选抽奖功能吗？
                </dt>
                <dd className="qa-list__data">
                  是的，你可以。 有可能将彩票系统整合到你公司管理的网站中。
                  <br />
                  请咨询有关费用。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以用PARKLoT来模拟运动的预期结果吗？
                </dt>
                <dd className="qa-list__data">
                  是的，我们可以根据以前活动的实际结果，为你提供模拟的预期结果。
                  我们可以根据以往活动的实际数字，为您提供模拟的预期结果，请不要犹豫，与我们联系。
                  <br />
                  (不保证结果)。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我能否阻止过去参加过同一活动的用户获胜？
                </dt>
                <dd className="qa-list__data">
                  是的，有可能设置后来的抽奖，而不是即时赢。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我需要一个特殊的页面吗？
                  我是否也可以要求PARKLoT制作一个特别页面？
                </dt>
                <dd className="qa-list__data">
                  不，普通的即赢活动不需要特别的页面。
                  <br />
                  如果你想提高你的品牌价值或计划通过诊断性内容进行传播，
                  <br />
                  我们可以帮助你创建一个特殊的页面。
                  请与我们联系以了解更多信息。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  例如，是否有黑名单登记功能？
                </dt>
                <dd className="qa-list__data">
                  目前，还没有黑名单登记功能。
                  <br />
                  你可以通过默认的个人资料账户和最低的追随者数量来设置你的赢利范围。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  是否有任何机制来防止非法抽奖？
                </dt>
                <dd className="qa-list__data">
                  是的，你可以将默认的配置文件账户设置为不当选。
                  <br />
                  它可以防止一定数量的多账户参赛，以达到获奖的目的。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  收到自动回复需要多少秒？ 是否有延迟？
                </dt>
                <dd className="qa-list__data">
                  根据环境的不同，该系统通常需要一分钟左右才能到达。
                  <br />
                  如果出现延误，请放心，我们会在适当的时候给予答复。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以收集有标签的帖子并在我的网站上发布吗？
                </dt>
                <dd className="qa-list__data">
                  是的，Twitter和Instagram上带有标签的帖子可
                  <br />
                  以被自动收集并显示在公司网站上。
                  <br />
                  只需嵌入我们发布的标签就可以开始了。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我可以开展活动来增加我的视频的观看次数吗？
                </dt>
                <dd className="qa-list__data">
                  是的，可以开展活动，让用户观看视频并参加测验，
                  <br />
                  或者自动将一个特殊的视频URL发给只转发的用户。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  我是否也可以要求开展一项活动，让人们在实体店参加邮票集会并抽签？
                </dt>
                <dd className="qa-list__data">
                  是的，这是有可能的。 更多信息请联系我们。
                </dd>
              </dl>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Qa
